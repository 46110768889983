import React from "react";
import SoftwareWidget from "../components/softwarewidget";

const Instructions = () => {
  const codeBlockStyle = {
    backgroundColor: "#000",
    padding: "10px",
    borderLeft: "5px solid #ccc",
    overflowX: "auto",
    marginBottom: "20px",
    color: "lime",
    fontFamily: "courier",
  };

  const noteStyle = {
    backgroundColor: "#ffffcc",
    padding: "10px",
    borderLeft: "5px solid #ffeb3b",
    marginBottom: "20px",
  };

  const sectionStyle = {
    fontSize: ".875rem",
    marginBottom: "20px",
  };

  const headerStyle = {
    color: "#0056b3",
    borderBottom: "2px solid #0056b3",
    padding: "5px",
    marginBottom: "20px",
  };
  const stepstyle = {
    margin: "20px", fontSize: "1.5rem", color: "#333",
   
    };
    

  return (
    <div className="flex  justify-center bg-slate-50 w-full h-auto   ">
      
    <div className=" items-center justify-center bg-slate-100 w-9/12 m-auto p-5 rounded-lg" style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", margin: "20px", color: "#333" }}>
    
      <h1 style={headerStyle}>Setup Instructions</h1>

      <div style={sectionStyle}>
        <h2 className=" border-l-4 border-slate-800  bg-cyan-200 p-2 text-cyan-800 text-2xl" > If docker installed skip to step 2 </h2>
        <h1 className="text-red-500 pt-8 border-b-2 border-b-red-500">if docker & docker composer not installed</h1>
        <h2 style={stepstyle}  > Step 1: Check and Install Docker </h2>
        <p>
          The script requires Docker and Docker Compose. If they are not installed, follow the instructions below based
          on your operating system:
        </p>

        <h3>Linux</h3>
        <pre style={codeBlockStyle}>
          sudo apt update
          {"\n"}sudo apt install -y apt-transport-https ca-certificates curl software-properties-common{"\n"}
          curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o
          /usr/share/keyrings/docker-archive-keyring.gpg{"\n"}
          echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/docker-archive-keyring.gpg]
          https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable" | sudo tee
          /etc/apt/sources.list.d/docker.list &gt; /dev/null{"\n"}sudo apt update{"\n"}sudo apt install -y docker-ce
          docker-ce-cli containerd.io{"\n"}
          {"\n"}sudo usermod -aG docker $USER{"\n"}# Log out and log back in for changes to take effect{"\n"}
          {"\n"}# Install Docker Compose{"\n"}sudo curl -L
          "https://github.com/docker/compose/releases/latest/download/docker-compose-$(uname -s)-$(uname -m)" -o
          /usr/local/bin/docker-compose{"\n"}sudo chmod +x /usr/local/bin/docker-compose
        </pre>

        <h3>macOS</h3>
        <p>
          Download and install <a href="https://www.docker.com/products/docker-desktop">Docker Desktop</a>. Docker
          Compose is included with Docker Desktop.
        </p>

        <h3>Windows</h3>
        <p>
          Download and install <a href="https://www.docker.com/products/docker-desktop">Docker Desktop</a>. Ensure WSL 2
          is enabled as Docker Desktop requires it. Docker Compose is included with Docker Desktop.
        </p>
      </div>

      <div style={sectionStyle}>
        <hr />
        <h2 className=" border-l-4 border-green-800  bg-green-200 p-2 text-slate-950 text-2xl">When you have Docker installed</h2>
        <h2 style={stepstyle} >Step 2: Download and Run the Script</h2>
        <SoftwareWidget title="Download the Free AI ImaGen" description="This image generator uses Stable Diffusion 3.5 via the Hugging Face API to create high-quality images from text prompts. It runs in a Docker container, making it easy to deploy and use on any machine. Simply get an API key from Hugging Face, run the container, and you're ready to generate unlimited images, all for free and at impressive speed. The setup is fast, efficient, and ideal for designers, developers, and creatives needing stunning visuals in seconds" filename="imagen.sh" link="instruction" />
        <ol>
          <li>Save the script to a file named <code>setup.sh</code>.</li>
          <li>
            Make the script executable:
            <pre style={codeBlockStyle}>chmod +x setup.sh</pre>
          </li>
          <li>
            Run the script:
            <pre style={codeBlockStyle}>./setup.sh</pre>
          </li>
        </ol>
      </div>

      <div style={sectionStyle}>
        <h2 style={stepstyle} >Step 3: Input Your Hugging Face API Key</h2>
        <p>During execution, you will be prompted to enter your Hugging Face API key:</p>
        <div style={noteStyle}>
          When prompted, paste your API key into the terminal window and press Enter.
        </div>
      </div>

      <div style={sectionStyle}>
        <h2 style={stepstyle} >Step 4: Build and Start the Application</h2>
        <p>The script will automatically:</p>
        <ul>
          <li>Clone the required repository (<code>imagegenerator</code>).</li>
          <li>Create a <code>docker-compose.yml</code> file and a Dockerfile.</li>
          <li>Build a Docker image.</li>
          <li>Start the application using Docker Compose.</li>
        </ul>
        <p>If everything runs successfully, you will see a message confirming that the application is running.</p>
      </div>

      <div style={sectionStyle}>
        <h2 style={stepstyle}  >Step 5: Access the Web Application</h2>
        <ol>
          <li>Open your web browser.</li>
          <li>
            Navigate to:
            <a className="text-blue-500 p-8 border-l-5 border-l-blue-500 font-bold" href="http://localhost:3000" target="_blank" rel="noopener noreferrer">
              http://localhost:3000
            </a>.
          </li>
          <li>The web application should now be accessible.</li>
        </ol>
      </div>

      <div style={sectionStyle}>
        <h2>Troubleshooting</h2>
        <p>If you encounter any issues:</p>
        <ul>
          <li>
            Ensure Docker and Docker Compose are installed by running:
            <pre style={codeBlockStyle}>
              docker --version
              {"\n"}docker-compose --version
            </pre>
          </li>
          <li>If errors occur during script execution, check for error messages in the terminal output.</li>
          <li>You can restart the containers if needed:
            <pre style={codeBlockStyle}>docker-compose down && docker-compose up -d</pre></li>
        </ul>
      </div>

      <footer style={{ marginTop: "30px", textAlign: "center" }}>
        &copy; 2024 Setup Guide
      </footer>
    </div>
    </div>
  );
};


export default Instructions