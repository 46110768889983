import DownloadButton from "./downloadbutton";
import Box from "./box";
import { Docker, Imagelogo } from "./logos";
import { Divider, Modal } from "@mui/material";
import { useCookies } from "react-cookie";
import LoginModal from "./loginmodal";
import { useState } from "react";
import Chip  from "@mui/joy/Chip";
import { Button } from "@mui/joy";
const SoftwareWidget = ({title="" , description="" , filename='info.txt' , className='' , link='' , logo=<Docker size={75}/> ,neu=false , install=true}  ) => {


  const [cookies, setCookie, removeCookie] = useCookies();
  const [modal, setModal] = useState(null);

  const handleDownload = () => {
    // Direct link to your ZIP file stored in the public folder
    const fileUrl = "../downloads/setup.zip"; // Change to your file's path
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = "example.zip"; // Suggested file name
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  return (
   

<div className={`border m-5 w-12/12 xs:w-full shadow-xl  mx-auto  hover:rounded-xl  duration-100 grid grid-cols-12 gap-2 lg:p-2 xs:p-1 ${className}`}  >
    
    
 <div className="flex flex-col h-full   gap-5 col-span-6  text-center text-2xl font-thin text-slate-950  ">
{ neu ? 
<div className="flex m-auto items-center w-full justify-center ">
<Chip className='p-2 w-full' color='danger'  variant="solid">New </Chip>
</div>:""
}          
          <div className="p-8 text-blue-400 font-thin">{title}</div>

         
         
          <i className="flex m-auto text-sm text-justify p-4 items-center " >{description}</i>
         <a className="text-sm p-4" href={`/${link}`}>Installation instructions</a>
        
        </div>

    <div className="col-span-6 text-center  font-thin text-slate-950  ">
<Box   text="" span={12} child={[
  logo ,

<div className="   flex  flex-col text-center items-center p-2   duration-300" > 

  
{cookies['login'] ? 
  (install ? 
 <DownloadButton text="Install" filename={filename} /> :

 <Button variant='outlined' className="w-96 h-24" sx={{background:'black' , color:'white' , fontSize:24, }} onClick={handleDownload}>Download</Button>
  )


 




:<LoginModal   />}


   
</div>, 

           ]
            
            
        } /> 

    </div>
       

         
           </div>

  
  
  );
}

export default SoftwareWidget;