
import { useState } from 'react';
import { DownLogo } from './logos';
import { useCookies } from "react-cookie";
import { Button } from '@mui/joy';
const DownloadButton = ({text , filename}) => {
  const [cookies , setCookie , removeCookie] = useCookies(['fileContent']);

 const [fileContent, setFileContent] = useState('');   

function fileReaderExample() {
 

  fetch("/downloads/"+filename).then((response) => {

  
    response.text().then((text) => {
        console.log(text)
        setFileContent(text)
    }

);

const blob = new Blob([fileContent], { type: 'text/bash' });
    
    // Create a temporary anchor element for downloading
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename; // File name with timestamp
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  });
}

  


  return (
 
    <div className='flex flex-col justify-center items-center w-48'>
   
      
      <Button variant="solid" size="sm" color='success' className= ' flex flex-col justify-center items-center w-full text-sm m-auto  ' onClick={fileReaderExample}>
       
        <DownLogo color='white' size={40}/>
         {text} 
        </Button> 

    </div> 
  );
};

export default DownloadButton;