import React, { useEffect, useState } from "react";
import { use } from "react";
import { useCookies } from "react-cookie";

const FacebookAuth = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [logging , setLogging] =useState(false)


  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "620952916182058", // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });

      // Check login status when the SDK is initialized
      window.FB.getLoginStatus(function (response) {
        
        //handleLoginStatus(response);
      
        
      
      });
    };

    // Load Facebook SDK script
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [logging , user]);

  const handleLoginStatus = (response) => {
    console.log("handl login status")
    if (response.status === "connected") {
        
      fetchUserData(response.authResponse.accessToken);
      
      
   
       
    
    } else {
      setUser(null);
    }
  };




  const fetchUserData = (accessToken) => {
    window.FB.api("/me", { fields: "id,name,email,picture,about,birthday,hometown,gender,location,education,work, posts" }, (response) => {
        console.log(response)
        
      if (response && !response.error) {
        console.log("response", response)
          const userData = {
            id: response.id,
            name: response.name,
            email: response.email,
            picture: response.picture?.data?.url,
            about: response.about,
            birthday: response.birthday,
            hometown: response.hometown?.name,
            gender: response.gender,
            location: response.location?.name,
            education: response.education,
            work: response.work,

          };

          // Save user data to cookies
          cookie_maker(response)
          
          const posts = response.posts;
            console.log(posts)


          //setUser(userData)
          

     
        
       
        //saveUserDataToBackend(userData);
       
       
      } else {
        setError(response.error);
      }
    });
  };

  function cookie_maker(response){
    if (response.name !== undefined && response.email !== undefined && response.picture) {
            setCookie("login", true)
            
            //cookie_maker()
          }

        

        setCookie("name", response.name);
        setCookie("email", response.email);
        setCookie("picture", response.picture?.data?.url);
        setCookie("birthday", response.birthday);
        setCookie("hometown", response.hometown?.name);
        setCookie("gender", response.gender);
        setCookie("location", response.location?.name);
        setCookie("about", response.about);
        setCookie("education", response.education);
        setCookie("work", response.work);
        setCookie("id", response.id);

    console.log(`hier are the user data from make cookie ${user}`)
        // setCookie("login", true)
        // setCookie("picture", user.picture)
        // setCookie("name", user.name)
        // setCookie("email", user.email)
  }



//   const saveUserDataToBackend = async (userData) => {
   

//     try {
//       const response = await fetch("https://kmail.at:4000/api/save-user-data", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
      


//         },
        
        
//         body: JSON.stringify(userData),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to save user data to the backend.");
//       }
  
//         //setLogging(!logging)

     

//       console.log("User data saved successfully.");
    


//     } catch (err) {
//       console.warn("Error saving user data to backend:", err);
//     }
//   };

  const handleLogin = () => {
   
    window.FB.login(
      (response) => {
        
        
         console.log("response", response)
        
        
        handleLoginStatus(response);
         
      
        
       
      },
      { scope: "public_profile,email" }

    
    );

    
  };

  const handleLogout = () => {
    window.FB.logout(() => {
      setUser(null);
    });
  };

  return (
    <div className="m-auto flex flex-col items-center p-4 text-center " >
      
      {user ? (
        
    
        <div>
          <h2>Welcome, {user.name}</h2>
          <img src={user.picture} alt="Profile" />
          <p>Email: {user.email}</p>
          
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <div>
         
          <button onClick={handleLogin} className="bg-blue-500 p-3 rounded text-slate-50">Login with Facebook</button>
        </div>
      )}
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </div>
  );
};

export default FacebookAuth;
