

const Imagelogo =()=>{
    return(
        <div className=" flex   w-36  p-5 h-36 rounded-full  justify-center items-center">
            <img src="../logo.png"  />
        </div>
    )
}

export default Imagelogo