
import useHass from "./hassioapi"
const HassioWidget = ({entity_id , attributes=[] , state=true}) => {
   const [loading, error, data] = useHass({entity_id});
  data &&  console.log(data)
  
   

    return(
        <div className="flex flex-col  text-sm    items-center w-48  m-auto justify-center text-center ">
           
 {(loading) ? "Loading..." : error ? `Error: ${error}` : state && data ? data.state.toUpperCase() : ""}    
 
 {

      
          data &&  data.attributes && attributes.map((attr) => {
              return (
                  <div className="flex flex-col  text-sm    items-center  m-auto justify-center text-center ">
                    <div className="flex text-sm ">
                       {` ${attr[0].replace("_", " ")} : ${data.attributes[attr[0]]} ${attr[1]}`} </div>
                  </div>
              )
          })
            
 }

       
         

        </div>
    )
}
export default HassioWidget;