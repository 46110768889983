
const Wavetop = () => {
  return (

<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1440 320">

  <path class="redone" d="M0,256l60-56c60-56,180-168,300-136s240,224,360,224,240-160,360-192,240,64,300,96l60,32"/>
   <path class="blueone" d="M0,256l60-56c60-56,180-168,300-136s240,224,360,224,240-160,360-192,240,64,300,96l60,32"/>
    <path class="greenone" d="M0,256l60-56c60-56,180-168,300-136s240,224,360,224,240-160,360-192,240,64,300,96l60,32"/>
    <path class="violett" d="M0,256l60-56c60-56,180-168,300-136s240,224,360,224,240-160,360-192,240,64,300,96l60,32"/>
    
  {/* <path class="greenone" d="M0,128l60,16c60,16,180,48,300,32s240-80,360-112,240-16,360,16,240,96,300,128l60,32"/>
  <path class="violett" d="M0,192l60-32c60-32,180-96,300-80s240,112,360,128,240-48,360-80,240,0,300,16l60,16"/>
  <path class="blueone" d="M0,192l60-32c60-32,180-96,300-80s240,112,360,128,240-48,360-80,240,0,300,16l60,16"/> */}
</svg>
)

 
};

const Wavebottom = () => {
  return (
   


<svg id="Layer_1" className="-z-100 " xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1440 320">
<path class="greenone" d="M0,128l60,16c60,16,180,48,300,32s240-80,360-112,240-16,360,16,240,96,300,128l60,32"/>
<path class="redone" d="M0,128l60,16c60,16,180,48,300,32s240-80,360-112,240-16,360,16,240,96,300,128l60,32"/>
<path class="blueone" d="M0,128l60,16c60,16,180,48,300,32s240-80,360-112,240-16,360,16,240,96,300,128l60,32"/>
<path class="violett" d="M0,128l60,16c60,16,180,48,300,32s240-80,360-112,240-16,360,16,240,96,300,128l60,32"/>
  
  {/* 
  <path class="violett" d="M0,192l60-32c60-32,180-96,300-80s240,112,360,128,240-48,360-80,240,0,300,16l60,16"/>
  <path class="blueone" d="M0,192l60-32c60-32,180-96,300-80s240,112,360,128,240-48,360-80,240,0,300,16l60,16"/> */}
</svg>

 

)

 
};
export {Wavetop, Wavebottom};