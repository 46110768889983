import React, { useState, useEffect } from "react";

const VisitorTrackerWithCookiesConsent = () => {
  const [region, setRegion] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [browserLanguage, setBrowserLanguage] = useState(null);
  const [operatingSystem, setOperatingSystem] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    analytics: false,
    marketing: false,
    functional: true,
  });
  const [visitCount, setVisitCount] = useState(0);
  const [lastVisit, setLastVisit] = useState("");
  const [visitedPages, setVisitedPages] = useState([]);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  useEffect(() => {
    const fetchRegion = async () => {
      const cachedData = localStorage.getItem("cachedRegionData");
      const cacheExpiry = localStorage.getItem("cacheExpiry");

      if (cachedData && cacheExpiry && new Date() < new Date(cacheExpiry)) {
        const parsedData = JSON.parse(cachedData);
        setRegion(parsedData.region);
        setIpAddress(parsedData.ip);
        return;
      }

      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        const detectedRegion = data.country_code === "EU" ? "EU" : "Other";

        setRegion(detectedRegion);
        setIpAddress(data.ip);

        // Cache the response with an expiration time of 24 hours
        localStorage.setItem(
          "cachedRegionData",
          JSON.stringify({ region: detectedRegion, ip: data.ip })
        );
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 24); // Cache for 24 hours
        localStorage.setItem("cacheExpiry", expiryDate.toISOString());
      } catch (error) {
        console.error("Error fetching region: ", error);
        setRegion("Other");
      }
    };

    const detectBrowserInfo = () => {
      const platform = navigator.platform;
      setBrowserLanguage(navigator.language || navigator.userLanguage);
      setOperatingSystem(platform || "Unknown");
    };

    fetchRegion();
    detectBrowserInfo();

    const savedPreferences = localStorage.getItem("cookiesPreferences");
    const accepted = localStorage.getItem("cookiesAccepted") === "true";
    if (savedPreferences) {
      setPreferences(JSON.parse(savedPreferences));
    }
    setIsAccepted(accepted);

    if (accepted) {
      if (!sessionStorage.getItem("sessionStarted")) {
        sessionStorage.setItem("sessionStarted", "true");
        let count = parseInt(localStorage.getItem("visitCount")) || 0;
        count += 1;
        localStorage.setItem("visitCount", count.toString());
        setVisitCount(count);

        const lastVisitDate = localStorage.getItem("lastVisit");
        setLastVisit(lastVisitDate || "This is your first visit!");
        localStorage.setItem("lastVisit", new Date().toISOString());
        setCookie("lastVisitCookie", new Date().toISOString(), 7);
      }

      const currentPage = window.location.pathname;
      const visited = JSON.parse(localStorage.getItem("visitedPages")) || [];
      const newVisit = { page: currentPage, timestamp: new Date().toISOString() };
      visited.push(newVisit);
      localStorage.setItem("visitedPages", JSON.stringify(visited));
      setVisitedPages(visited);
    }
  }, [isAccepted]);

  const handleAccept = () => {
    setIsAccepted(true);
    const allPreferences = { analytics: true, marketing: true, functional: true };
    setPreferences(allPreferences);
    localStorage.setItem("cookiesAccepted", "true");
    localStorage.setItem("cookiesPreferences", JSON.stringify(allPreferences));
  };

  const handleCustomize = () => setShowPreferences(true);

  const handleSavePreferences = () => {
    setIsAccepted(true);
    setShowPreferences(false);
    localStorage.setItem("cookiesAccepted", "true");
    localStorage.setItem("cookiesPreferences", JSON.stringify(preferences));
  };

  const handlePreferenceChange = (key) => {
    setPreferences((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  if (!region) return null;

  if (!isAccepted) {
    return (
      <div className="cookies-banner fixed top-0 left-0 w-full h-full text-white flex items-end z-50">
        <div className="bg-gray-800/[.9] p-4 shadow-lg rounded-t-lg w-full    ">

        
        {showPreferences ? (
          <div className="preferences-modal bg-white  text-gray-800 p-4 rounded shadow-lg max-w-md justify-center mx-auto">
            <h3 className="text-lg font-bold mb-4">Customize Cookie Preferences</h3>
            <label className="block mb-2">
              <input
                type="checkbox"
                checked={preferences.analytics}
                onChange={() => handlePreferenceChange("analytics")}
                className="mr-2"
              />
              Enable Analytics Cookies
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                checked={preferences.marketing}
                onChange={() => handlePreferenceChange("marketing")}
                className="mr-2"
              />
              Enable Marketing Cookies
            </label>
            <label className="block mb-4">
              <input
                type="checkbox"
                checked={preferences.functional}
                onChange={() => handlePreferenceChange("functional")}
                className="mr-2"
              />
              Enable Functional Cookies
            </label>
            <button
              onClick={handleSavePreferences}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Save Preferences
            </button>
          </div>
        ) : (
          <div className="flex  items-center justify-between text-center">
            {region === "EU" ? (
              <p className="text-sm mb-4">
                We use cookies to enhance your experience and for analytics. You can accept all cookies or customize your preferences. Read our{" "}
                <a href="/privacy" className="text-blue-400 underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="/terms" className="text-blue-400 underline">
                  Terms of Service
                </a>
                .
              </p>
            ) : (
              <p className="text-sm mb-4">
                This site uses cookies to improve your browsing experience. By continuing, you agree to our use of cookies. Read our{" "}
                <a href="/privacy" className="text-blue-400 underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="/terms" className="text-blue-400 underline">
                  Terms of Service
                </a>
                .
              </p>
            )}
            <div className="flex space-x-2">
              <button
                onClick={handleAccept}
                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
              >
                Accept All
              </button>
              <button
                onClick={handleCustomize}
                className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700"
              >
                Customize Preferences
              </button>
            </div>
          </div>
        )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* <div className="tracking-info">
        <h3>Visitor Information:</h3>
        <p>IP Address: {ipAddress}</p>
        <p>Region: {region}</p>
        <p>Browser Language: {browserLanguage}</p>
        <p>Operating System: {operatingSystem}</p>
        <h4>Visit Count: {visitCount}</h4>
        <p>Last Visit: {lastVisit}</p>
        <h4>Visited Pages:</h4>
        <ul>
          {visitedPages.map((visit, index) => (
            <li key={index}>
              {visit.page} - {new Date(visit.timestamp).toLocaleString()}
            </li>
          ))}
        </ul>
      </div> */
      ""
      }
    </div>
  );
};

export default VisitorTrackerWithCookiesConsent;
