
import { Divider } from "@mui/material";
import { useCookies } from "react-cookie";
import {Wavebottom } from "./wave";
const Footer = () => {
const [cookies, setCookie, removeCookie] = useCookies();





    return (







        
        
               
                <div className="flex w-full h-48 border  bg-slate-950 justify-center items-end " >






 
  <div className={`flex  flex-col  w-12/12     text-slate-50 text-sm   text-center m-auto  ` } >
  <div className="p-8 flex col-span-12 row-span-4 m-auto  items-center justify-center h-12 w-12  text-3xl text-red-600 bg-slate-50 rounded-full kmail font-bold  "  >Ai</div>
    <a className="  " > Designed and developed by Mohammed Kmail | Vienna, Austria | Get in touch:</a>

    
    <a href="mailto://kmail@kmail.at">kmail@kmail.at</a> 
    
    
     <a className=" " href="/privacy">Privacy</a>
     
    
    </div>
 
</div>



        
   
       
       
    );
    }
    export default Footer;