
import { useCookies } from "react-cookie";
import { useEffect , useState } from "react";

import { Openai, Teams, Python, Huggingface, Docker, Javascrip ,Architecture , Ailogo, Imagelogo} from "../components/logos";
import { Button } from "@mui/joy";


import { Aiservices } from "../components/home_sections";
import { useNavigate } from "react-router-dom";


import Box from "../components/box";



const Home =()=>{
  const navigate = useNavigate();


const [word , setWord] = useState("Transform")
const [color , setColor] = useState("")   

           function shufflewords() {
            var result = '' 
            const  words = [ "Large language models", "Docker containers", "Python", "React", "Fullstack","CLI", "Generative AI","RAG","Data Science" , "Machine Learning", "Deep Learning", "Neural Networks", "Natural Language Processing", "Computer Vision", "Artificial Intelligence", "Software Development", "Web Development", "Frontend", "Backend", "API", "REST", "GraphQL", "Databases", "SQL", "NoSQL", "MongoDB", "PostgreSQL", "MySQL", "SQLite", "Cloud", "AWS", "Azure", "Google Cloud", "CI/CD", "Docker", "Kubernetes", "DevOps", ]
            const colors = ["red" , "blue" , "green" , "yellow" , "purple", "orange" , "pink" , "brown" , "gray"  , "slate"]
            const random = Math.floor(Math.random() * words.length);
            const randomcolor = Math.floor(Math.random() * colors.length);
            
        
            setWord(words[random])
            
            setColor(colors[randomcolor])
            console.log( randomcolor)
       
                

            
                
                
            
            }
            
           
           
              useEffect(()=>{
                const interval = setInterval(() => {
                  shufflewords()
                }, 2000);
                return () => clearInterval(interval);
              }
                ,[])



const [logging , setLogging] =useState(true)
const [cookies, setCookie, removeCookie] = useCookies();



    return(
        <div className="flex flex-col mx-auto   ">
      

            



<div className="flex flex-col m-auto text-center text-9xl  text-slate-700   h-48 items-center p-8 ">
  {/* <HomeAssistantWebSocket />  */}
  
  
  <div className={`text-xl text-${color}-500 city  `}>Hi, I'm Mohammed, a full-stack developer, Ai expert & Architect.</div>
  <div className= {`duration-700  flex col-span-12 text-${color}-500   w-full h-full items-center justify-center text-5xl  font-black city  `}   >{word}</div>
</div>


       
   
   
          
<div className="grid grid-cols-12    m-auto w-full"  > 


           <Box text="AI-supported architectural design" span={6}  child={<Architecture size={100} />}
           
         
           />
          
           <Box text="Artificial Intelligence Solutions"  fun={
            ()=>{navigate("/downloads")}
           } span={6} child={<Ailogo size={100}     />}
           
           children={<Button color="neutral" variant="soft" 
           onClick={
            ()=>{navigate("/downloads")}
           } 
           >Visit download page for the latest software</Button>}
           
           />
      

           
                
             

           </div>
    <hr className="p-5"></hr>
           
<Aiservices />

 <div className="flex w-full  col-span-12     bg-slate-50 ">
        <div className="w-full m-auto flex flex-row justify-evenly uppercase " >
<div className="text-center w-full  shadow h-96  text-3xl ">

            <div className=" flex justify-evenly w-full items-center h-full " >
                
   
  
    
     <Openai size={100} />
   
        <Teams size={100} />
        <Python size={100} />
        <Javascrip size={100} />
        <Huggingface size={100} />
        <Docker size={100} />
        
 </div>
 </div>
 </div>
        
           
        

    
    </  div>
           
        </div>
    )
}
export default Home