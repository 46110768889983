import Menu from "./menu";
import { Stack } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useCookies } from "react-cookie";
import { useEffect , useState } from "react";
import {Wavetop} from "./wave";



const Header = ()=>{
const [cookies, setCookie, removeCookie] = useCookies();







    return(
        
        <Stack direction={"row"} className={`flex flex-col   `} >
    
       
  
  
    <div className="w-9/12 ">
    
  <div className="wave-container-top" >
<Wavetop />
      </div>

              
 <Menu />
           

           </div>
           
            
           
        </Stack>
        
    )
}

export default Header