import { Efficiency , Customization , Innovation , Effective } from './logos';
import { Divider } from '@mui/material';
const Aiservices = () => {
    return (
        
        <div>

      
        <div className={`aiservices flex flex-col   h-96  w-12/12  bg-blue-500  grid grid-cols-12 grid-rows-12  `}>
            <div className="flex col-span-12 row-span-4 m-auto  items-center justify-center h-24 w-24  text-3xl text-slate-950 bg-slate-50 rounded-full kmail font-bold  "  >Ai</div>
          
           <div className="  col-span-12 row-span-3  flex items-center  w-full justify-center text-3xl text-slate-50 ">
            
            Architectural design & BIM</div>
           <div className="col-span-3 m-auto row-span-3   text-sm text-slate-50 text-justify"  ></div>
           <div className="col-span-6 m-auto  row-span-3  text-sm text-slate-50 text-justify  h-full w-full"  >At KMAIL, we bring the future to your fingertips with cutting-edge AI services tailored for your success. Whether you're in business, education, or creative industries, our suite of intelligent tools ensures you stay ahead of the game.</div>



    </div>
    <div className="flex m-auto text-center w-full justify-center text-4xl p-8 col-span-12 ">
        Why Choose Us?
    </div>
    <div className=" grid grid-cols-12 col-span-12 row-span-12  m-auto  items-center justify-center text-2xl text-slate-950  p-8   " >

    <div className="flex flex-col m-auto text-center w-full justify-center items-center text-sm p-8 col-span-3 h-48 "> 
        <Efficiency size={100} />
        <div className="text-3xl font-black text-green-500" >Efficiency</div> Save hours on repetitive tasks.</div>

     <div className="flex flex-col m-auto text-center w-full justify-center items-center text-sm p-8 col-span-3 h-48 "> 
        <Customization size={100} />
        <div className="text-3xl font-black text-green-500" >Customization</div> Tailored solutions for your unique needs.</div>

      <div className="flex flex-col m-auto text-center w-full justify-center items-center text-sm p-8 col-span-3 h-48">
        <Innovation size={100} />
         <div className="text-3xl font-black text-green-500" >Innovation</div> Stay ahead with the latest in AI advancements.</div>

       <div className="flex flex-col m-auto text-center w-full justify-center items-center text-sm p-8 col-span-3 h-48"> 
        <Effective size={100} />
        <div className="text-3xl font-black text-green-500" >Cost-Effective</div> Improve output while reducing costs.</div>
    </div>
    <Divider />


        <div className="aiservices flex flex-col   h-96  w-12/12  bg-pink-500  grid grid-cols-12 grid-rows-12   ">
            <div className="flex col-span-12 row-span-4 m-auto  items-center justify-center h-24 w-24  text-3xl text-slate-950 bg-slate-50 rounded-full kmail font-bold  "  >Ai</div>
          
           <div className="  col-span-12 row-span-3  flex items-center  w-full justify-center text-3xl text-slate-50 ">AI Development & Tools Creation</div>
           <div className="col-span-3 m-auto row-span-3   text-sm text-slate-50 text-justify"  ></div>
           <div className="col-span-6 m-auto  row-span-3  text-sm text-slate-50 text-justify  h-full w-full"  >At KMAIL, we bring the future to your fingertips with cutting-edge AI services tailored for your success. Whether you're in business, education, or creative industries, our suite of intelligent tools ensures you stay ahead of the game.</div>



    </div>

  
    {/* <div className="grid grid-cols-12 mx-auto    h-auto     text-slate-950   p-5 m-5 " >
 
<div className=" flex flex-col  col-span-4    items-center   text-slate-950  shadow  m-4      " >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     
                     <div className="p-2 text-2xl ">Retrieval-Augmented Generation (RAG)</div>
                     <p className="  p-2 text-justify  ">RAG combines advanced search with generative AI to retrieve relevant information from vast data sources and generate actionable, context-aware outputs. It simplifies complex tasks like summarizing reports, analyzing documents, or extracting insights from unstructured data. Perfect for professionals in research, law, education, and strategy, RAG saves time, enhances decision-making, and ensures precision.</p>
                </div>
                    <div className="flex flex-col col-span-4  items-center   text-slate-950   shadow  m-4   " >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     <div className="p-2 text-2xl">Chatbots</div>
                     <p className="  p-2 text-justify ">Chatbots are intelligent virtual assistants that provide real-time, 24/7 support, handling tasks like answering questions, offering personalized recommendations, and managing customer queries. They streamline workflows, schedule appointments, track orders, and integrate with systems to deliver seamless support across multiple platforms. With advanced natural language processing, they respond in a human-like manner, enhancing user experience and satisfaction while reducing operational costs and boosting efficiency.</p>
                </div>
                    <div className="flex flex-col col-span-4  items-center   text-slate-950   shadow  m-4   "  >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     <div className="p-2 text-2xl">Text Generation</div>
                     <p className="  p-2 text-justify ">AI-powered text generation creates high-quality, engaging, and tailored content for blogs, articles, and storytelling. It adapts to your brand voice, tone, and objectives, crafting persuasive posts, compelling narratives, or educational materials effortlessly. Perfect for marketers, educators, and creators, this technology saves time, ensures consistency, and keeps ideas flowing, empowering you to communicate effectively and creatively.</p>
                </div>
                <div className="flex flex-col  col-span-4  items-center justify-center  text-slate-950   shadow  m-4   "  >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     <div className="p-2 text-2xl">Email Writing</div>
                     <p className="  p-2 text-justify ">AI-powered email writing simplifies crafting professional, impactful emails, from business proposals to personalized messages. These tools analyze context, tone, and purpose to create polished content with optimized subject lines and clear, effective body text. With features like grammar checks, tone adjustments, and tailored suggestions, they save time while ensuring professionalism. Perfect for customer inquiries, client relations, or team coordination, AI email writing enhances clarity, confidence, and efficiency in every message</p>
                
                    
          </div>
          <div className="flex flex-col  col-span-4  items-center justify-center  text-slate-950  shadow  m-4  "  >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     <div className="p-2 text-2xl">Report Reading & Writing</div>
                     <p className="  p-2 text-justify ">AI-powered tools streamline the reporting process by summarizing lengthy documents, highlighting key insights, and identifying trends or discrepancies quickly. For creation, they generate polished, well-structured reports tailored to professional needs, such as project updates, financial summaries, or research analyses. By automating reading and writing, AI saves time, enhances accuracy, and ensures clarity, empowering professionals to focus on decision-making and achieving more.</p>
                
                    
          </div>

          <div className="flex flex-col  col-span-4  items-center justify-center  text-slate-950  shadow  m-4  "  >
                     <div className="flex justify-center font-bold  h-12 w-12 kmail border border-slate-950 rounded-full items-center ">Ai</div>
                     <div className="p-2 text-2xl">Image Generation</div>
                     <p className="  p-2 text-justify ">AI-powered image generation transforms ideas into professional-quality visuals, perfect for marketing, design, storytelling, and more. Simply describe your vision, and AI creates stunning, detailed images—from photorealistic renderings to artistic illustrations. It’s a game-changer for industries like advertising, e-commerce, and architecture, enabling rapid iteration and captivating visuals. With customizable styles, precise detailing, and fast production, AI makes visual creativity accessible, efficient, and limitless, empowering professionals and enthusiasts alike.</p>
                
                    
          </div> </div> */}

        </div>

         
    
   

          
         
          
    );
}






export {Aiservices};
        