
    
    const Box = ({text , color , child , children , span , fun} ) => {
        const style =  `  flex flex-col col-span-${span} text-3xl h-96 w-full  bg-${color}-50 gap-5  cursor-pointer items-center justify-center text-slate-950 font-thin   `
    
        return(<div onClick={fun} className={style}>
            {child}
                  {text}
            {children}

                
                
               </div>)
    }
    
    export default Box;
    