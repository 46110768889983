import React from "react";

const PrivacyTerms = () => {
  return (
    <div className="bg-gray-100 text-gray-800">
      <div className="container mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
        <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy for kmail.at</h1>
        <p className="text-sm text-gray-600 text-center mb-10">Effective Date: 01.01.2024</p>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
          <p className="mb-4">
            Welcome to kmail.at! We greatly value the trust you place in us and your privacy is
            something we take very seriously. This Privacy Policy is designed to provide you
            with a detailed understanding of how we collect, process, use, protect, and share
            your personal data. Our goal is to ensure you have full transparency and confidence
            when using our services.
          </p>
          <p className="mb-4">
            By using our platform and services, you acknowledge that you have read and understood
            this Privacy Policy. If you have any concerns or do not agree with any part of this
            policy, we encourage you to reach out to us at
            <a href="mailto:kmail@kmail.at" className="text-blue-500 underline"> kmail@kmail.at</a>
            for clarification or assistance. Your questions and feedback are important to us.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">2. Legal Basis for Processing</h2>
          <p className="mb-4">
            At kmail.at, we process your personal data in compliance with the principles and
            guidelines outlined in the EU General Data Protection Regulation (GDPR). We rely on
            the following legal bases to process your data:
          </p>
          <ul className="list-disc pl-6">
            <li className="mb-2">
              <strong>Consent</strong>: Your explicit consent is obtained whenever required. For
              example, when you sign up for newsletters or agree to specific services, your
              permission allows us to process your data.
            </li>
            <li className="mb-2">
              <strong>Contractual Necessity</strong>: To provide you with the services you request,
              such as account management or customer support, your data is processed to fulfill
              our contractual obligations to you.
            </li>
            <li className="mb-2">
              <strong>Legal Obligation</strong>: Sometimes, we may need to process your data to
              comply with legal obligations, such as record-keeping or responding to lawful
              requests from authorities.
            </li>
            <li className="mb-2">
              <strong>Legitimate Interests</strong>: We process your data to enhance our services,
              ensure the security of our platform, and prevent fraudulent activities. This is done
              in a manner that respects your rights and interests.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">3. Information We Collect</h2>
          <p className="mb-4">
            To deliver the best possible experience and ensure that our services meet your needs,
            we collect various types of information. This section provides an in-depth look at the
            data we gather and how we obtain it.
          </p>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">3.1 Information You Provide Directly</h3>
            <ul className="list-disc pl-6">
              <li className="mb-2">
                <strong>Personal Details</strong>: This includes information such as your name,
                email address, and other contact details. You provide this data when signing up,
                subscribing to newsletters, submitting inquiries, or engaging with our support
                team.
              </li>
              <li className="mb-2">
                <strong>Login Credentials</strong>: As we expand our platform to include login
                options via third-party providers such as Facebook, Google, and Apple, we will
                collect the necessary authentication data to enable secure and seamless access.
              </li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">3.2 Information We Collect Automatically</h3>
            <ul className="list-disc pl-6">
              <li className="mb-2">
                <strong>Usage Data</strong>: We track your interactions with our website, such as
                pages visited, the duration of your visit, and the links you click. This helps us
                understand user behavior and refine our platform to better serve your needs.
              </li>
              <li className="mb-2">
                <strong>Cookies and Tracking Technologies</strong>: To improve functionality and
                tailor the user experience, we employ cookies and similar technologies. Essential
                cookies are necessary for the website to function, while non-essential cookies
                require your consent.
              </li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">3.3 Information from Third Parties</h3>
            <p>
              If you choose to log in or interact with our services using third-party platforms,
              such as social media providers, we may collect information based on the permissions
              you grant. This can include your name, email address, profile picture, and other
              details as outlined by their respective privacy policies.
            </p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">4. Use of Facebook Login Data</h2>
          <p className="mb-4">
            If you choose to log in using Facebook, we will collect and process only the information
            necessary to provide seamless access to our services. This may include your name, email
            address, and profile picture as permitted by Facebook’s privacy policies and the
            permissions you have granted.
          </p>
          <p className="mb-4">
            All data collected via Facebook login will be stored securely in accordance with GDPR
            regulations. It will be used solely for the purposes of user authentication, enhancing
            your experience, and providing access to our services. We do not share this data with
            third parties unless explicitly required for legal or operational purposes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">5. Deletion of Your Data</h2>
          <p className="mb-4">
            You have the right to request the deletion of your personal data at any time. To delete
            your data, please follow these steps:
          </p>
          <ol className="list-decimal pl-6">
            <li className="mb-2">
              Send an email to <a href="mailto:kmail@kmail.at" className="text-blue-500 underline">kmail@kmail.at</a> with the subject line
              "Data Deletion Request" and include your account details for verification purposes.
            </li>
            <li className="mb-2">
              We will verify your identity to ensure the request is legitimate and complies with
              GDPR requirements.
            </li>
            <li className="mb-2">
              Once verified, we will delete your data from our systems and notify you of the
              successful deletion within 30 days.
            </li>
          </ol>
          <p className="mb-4">
            Please note that certain data may need to be retained for legal or operational purposes,
            in which case you will be informed.
          </p>
        </section>

        <footer className="text-center text-gray-600 mt-10">
          <p>
            If you have any questions, concerns, or feedback about this Privacy Policy, please
            don't hesitate to reach out to us at
            <a href="mailto:kmail@kmail.at" className="text-blue-500 underline"> kmail@kmail.at</a>.
          </p>
          <p>&copy; 2024 kmail.at. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyTerms;
