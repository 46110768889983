import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandCircleDown , ExpandMoreOutlined , Add } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { Pdf, Website, Youtube } from './logos';


const AccordionElement = ({title , content , icon , color = "white"}) => {
    return(
        <Accordion  sx={{ backgroundColor: 'Transparent' , boxShadow: 'none'   , p: 0 , m: 1 , width: 'full' , display: 'flex' , flexDirection: 'column' , border:"none" , color:{color} }}>
        <AccordionSummary sx={{ backgroundColor: 'Transparent' , border:"none"   }}
          expandIcon={<Add fontSize='small'  sx={{color:{color}, height:"30px", fontSize:"30px" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <Typography variant="h6"  >{title}</Typography>
        </AccordionSummary >
        
        <AccordionDetails >
            <div className='flex m-auto items-center w-full justify-center p-4'>
{icon}
            </div>

            
      {content}
        </AccordionDetails>
      </Accordion>
);

}





const AccordionUsage=()=> {
  return (
    <div>
         <div className="py-2 ">  Retrieval-Augmented Generation (RAG) is a powerful tool for accessing and synthesizing information efficiently. By combining advanced retrieval methods with generative AI, RAG offers unparalleled benefits: </div>
<hr className="m-8"></hr>
   
<AccordionElement title="Access Diverse Data Sources" content="In today’s information-rich world, data exists in various forms and formats—ranging from static PDFs and academic research papers to dynamic video content and ever-changing websites. RAG empowers you to retrieve and summarize valuable content from all these diverse sources in one seamless, integrated process. It bridges the gap between unstructured and structured data, making previously inaccessible insights readily available at your fingertips." icon={<Pdf size={100} color='white'/>}/> 

<AccordionElement title="Save Time and Effort" content="Manually searching for relevant information across multiple platforms and formats is time-consuming and often tedious. With RAG, the process becomes instantaneous. By delivering precise, context-aware answers, RAG eliminates the need for sifting through countless documents, transcripts, or pages of text. This means you spend less time looking for information and more time utilizing it for decision-making or innovation." icon={<Website size={100} color ="white" />}/>
<AccordionElement title="Enhance Accuracy and Context" content="RAG’s ability to understand and interpret complex queries ensures that the information it retrieves is not only accurate but also contextually relevant. By leveraging advanced language models and AI algorithms, RAG goes beyond keyword matching to provide nuanced, insightful responses that address the underlying intent of your queries. This ensures that you receive comprehensive, high-quality information tailored to your specific needs." icon={<Youtube size={100} color='white'/>}/>
<AccordionElement title="Stay Up-to-Date with Real-Time Information" content="In a rapidly evolving world, staying informed with the latest updates is crucial. RAG integrates seamlessly with online resources, enabling it to retrieve real-time information from websites and other digital platforms. This ensures that the knowledge you access is not only relevant but also up-to-date, keeping you ahead in your field." icon={<Pdf size={100} color='white'/>}/>
<AccordionElement title="Enhanced Productivity and Focus" content="By automating the process of information retrieval and summarization, RAG significantly reduces cognitive load and streamlines workflows. Instead of being bogged down by repetitive tasks like data gathering or note-taking, you can focus your energy on high-value activities such as strategy development, creative problem-solving, or research innovation." icon={<Youtube size={100} color='white'/>}/> 
<AccordionElement title="Versatility Across Use Cases" content="RAG is not limited to a single domain or application. Its versatility makes it ideal for various scenarios, including academic research, corporate analysis, content creation, legal discovery, and more. No matter your field or focus, RAG adapts to your needs, delivering customized solutions that align with your goals." icon={<Pdf size={100} color='white'/>}/>

<hr className="m-8"></hr>
   
        In summary, RAG is more than just a technology—it is a game-changing tool that empowers you to unlock the full potential of information. By combining speed, accuracy, and accessibility, RAG transforms how you interact with data, making it an indispensable resource in an increasingly data-driven world. Whether you're extracting knowledge, analyzing trends, or staying informed, RAG is the intelligent choice for efficient and impactful information retrieval.
          
          
     
    </div>
  );
}

export  {AccordionUsage , AccordionElement} ;
