import DevelopmentTimeChart from "../components/graph";
const Container = () => {
    return (
        <div className="h-screen flex flex-col items-center justify-center">
            <h1 className="text-4xl">Containerization</h1>
            <DevelopmentTimeChart />
        </div>
    );
};

export default Container;