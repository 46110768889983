
import { useEffect , useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

import VCard from "../components/card";

import {BottomNavigation, Card , Divider} from "@mui/material";

import { Wavetop } from "../components/wave";
import HuggingFaceImageGenerator from "../components/hugginface";
import Texter from "../components/textgeneration";
import {Button} from "@mui/material";
import { Youtube , Pdf, Arxiv, Website, Huggingface, DownLogo } from "../components/logos";
import {AccordionUsage} from "../components/ragAccord";
import SoftwareWidget from "../components/softwarewidget";
import { Typography } from "@mui/joy";


const Ai =() => {
    const[more , setMore] = useState(false)
   


    const [formData, setFormData] = useState({
        question: '',
        link: ''
    })

 const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
    
    
    

 
        //const [data, loading , error] = useFetch(endpoint ="http://127.0.0.1:8002/test" , question=question);

  const [cards, setCards] = useState([])
  const [ais , setAis] = useState([])
useEffect(()=>{
    fetch("cards.json" , {
        method:"GET",
        headers:{
            "Content-Type": "application/json",
            "mode": "no-cors"
        }

    }).then((response) => response.json()).then((data) => {
        console.log(data)
        setCards(data["cards"])
        setAis(data["ai"])

    }
    )
},[])





 //const {textcolor , bordercolor , bgcolor} = colorer()






//const colors={"textcolor" :textcolor , "bordercolor": bordercolor , "bgcolor":bgcolor}

    return (
        <div className='mx-auto w-12/12   my-5   '>
        
            
            <div className="flex flex-col m-auto  w-96 h-96 rounded-full  my-8  city ">
                <div className=" m-auto flex flex-col  duration-700  p-12 rounded-full hover:shadow-xl   justify-center  hover:backdrop-blur-3xl w-96 h-96   ">
                    <h1 className="  text-9xl text-center font-black text-slate-200 city  ">AI</h1>
                  
                    <p className="text-center text-sm text-slate-950 font-thin">Tools and Techniques</p>
                </div>
                
                
            </div>
       
<a href="/downloads">
        <Typography level="h1" className="text-center" color="success" >
            
               Downlaod Center
 
          
<div className="flex justify-center gap-4">
<DownLogo size={100} color="green" />
</div>
            
        </Typography>
        </a>
        

           <div className=" bg-cyan-400 grid grid-cols-12 gap-2 duration-300  flex-col w-full    p-8 items-center   justify-center my-12">
                {
            //-----------------------------  RAG  --------------------------------  
            }
            <div  className="shadow font-thin  backdrop-blur-3xl backdrop-brightness-50  col-span-5  p-8 flex flex-col items-center justify-center text-slate-50">

 <div className="   text-9xl  drex font-thin ">RAG</div>
 <div className="  font-thin text-xl  ">Retrival augmented generation</div>
            <div className="text-4xl  font-thin ">Why You Should Use RAG?</div>
            
            <div className="p-8 w-full flex justify-between h-48" ><Youtube color="white" size={100} /> <Pdf size={80} color="white"/> <Website size={100} color="white"/> <Arxiv color="white" size={100}/></div>
            <div className="flex flex-col p-4 w-full">
                {
                    more &&  
                    
                    <div className="text-slate-50 text-sm   text-justify">
                        <AccordionUsage />
                       
                          
                        

                    </div>
                }
<Button variant="plain" color="warning" onClick={()=>{setMore(!more)}}>{
    more ? "hide" : "More"
    }</Button>
            </div>
            </div>
           
                    <div className=" col-span-1">  </div>


<div className="mx-4 content-center text-center shadow h-full   col-span-6  p-4    items-center  text-slate-50 backdrop-blur-3xl backdrop-brightness-50  ">
 <div className="text-9xl  drex   ">GAI</div>
 
  <div className="  font-thin text-xl  justify-center text-center content-center items-center m-auto flex  flex-col ">Generative Artificial Intelligence 
    <Huggingface size={100}  />
    </div> 
  
  <div className="text-2xl  font-thin text-center p-4 ">Try Image generator using Huggingface Api</div>
  
     <HuggingFaceImageGenerator />
</div>
          
            </div>
    



<div className="flex w-full border col-span-12 h-96  items-center  bg-slate-950 overflow-hidden image-container">
 <div className="overlay-text text-slate-50 city  " >
    Unlock the Power of AI
    <p className="text-sm text-justify font-thin"> 
AI transforms industries and everyday life by automating tasks, uncovering data insights, and enhancing communication through virtual assistants. It drives innovation in image analysis, robotics, and healthcare, delivering personalized experiences, creative solutions, and smarter security. Experience a future powered by AI.</p>
    </div>
        
           
        
    
    </  div>
<div className=" gap-2 ">

   



<div className="flex m-auto w-12/12  shadow bg-slate-100/[.9] text-slate-50 gap-2 p-8 my-5 ">

   

<HuggingFaceImageGenerator />
</div>


<div className="m-auto w-9/12 grid grid-cols-12 gap-2 p-5">



{
    ais && ais.map((ai , key ) => {
        return(
            <div key={key} className={`flex flex-col   col-span-${ai.width} h-96   border } hover:border-blue-500} m-auto  w-full  text-4xl gap-4 bg-slate-50/[.95] p-4 text-slate-400 hover:text-slate-950 shadow-xl duration-300  `}>
                {ai.title}
                <Divider/>
                <div className="overflow-auto scrollbar_hidden ">
                     {
                    ai.content && ai.content.map((content , key) => {
                        return(
                            <ReactMarkdown className="text-sm text-justify first-letter:text-xl   font-thin  " key={key} remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
                        )
                    }
                    )
                }
                </div>

               
               
            </div>
        )
    })
}
</div>


 <div className="m-auto  flex w-9/12  h-96  items-center  overflow-hidden image-container ">
 <div className=" opacity-0 hover:opacity-100 overlay-text hover:bg-slate-50/[.5] duration-300 content-center h-96   w-full" >
    Ai generated Image
    <p className="text-sm"> image generated using Huggingface api</p>
    </div>
         <img className="flex flex-fit w-full   " src="images/generated_image (1).png">   
           
        </img>
    
    </  div>

<div className="m-auto w-9/12 grid grid-cols-12 gap-2 p-5">
{
 
 

cards && cards.map((card , key ) => {
        return(
            <VCard key={key} content={card.content} imgurl={card.image} detail={card.detail} title={card.title} datum={card.date} sc={card.sc} link={card.link}/>
        )
    }
    )
}
</div>

</div>


            
            
        </div>
    )
}

export default Ai
