import React from 'react';
import SoftwareWidget from '../components/softwarewidget';

const ChatbotSetupInstructions = () => {

    const codeBlockStyle = {
    backgroundColor: "#000",
    padding: "10px",
    borderLeft: "5px solid #ccc",
    overflowX: "auto",
    marginBottom: "20px",
    color: "lime",
    fontFamily: "courier",
  };

  const noteStyle = {
    backgroundColor: "#ffffcc",
    padding: "10px",
    borderLeft: "5px solid #ffeb3b",
    marginBottom: "20px",
  };

  const sectionStyle = {
    fontSize: ".875rem",
    marginBottom: "20px",
  };

  const headerStyle = {
    color: "#0056b3",
    borderBottom: "2px solid #0056b3",
    padding: "5px",
    marginBottom: "20px",
  };
  const stepstyle = {
    margin: "20px", fontSize: "1.5rem", color: "#333",
   
    };
const linkstyle = { color: '#007bff', textDecoration: 'none' };
    

    return (
        <div className='flex  justify-center bg-slate-50 w-full h-auto items-center flex-col p-5 '> 
            <header style={headerStyle}>
                <h1>Chatbot Setup Instructions</h1>
            </header>
            <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', background: 'white', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <h2 style={stepstyle}>Step 1: Verify Docker and Docker Compose Installation</h2>
                <p>Before proceeding, ensure that Docker and Docker Compose are installed on your local machine:</p>
                <ul>
                    <li>Open your terminal or command prompt and run the following commands:
                        <pre style={codeBlockStyle}>docker --version</pre>
                        <pre style={codeBlockStyle}> docker-compose --version</pre>
                    </li>
                    <li>If either command does not return a version number, visit <a href="https://www.docker.com/get-started/" target="_blank" style={linkstyle}>Docker's Get Started page</a> to download and install Docker Desktop, which includes Docker Compose.</li>
                </ul>

                <h2 style={stepstyle}>Step 2: Download the Setup Script</h2>
                <p>Follow these steps to get the setup script:</p>

                <SoftwareWidget description='This software is a Docker-based chatbot system featuring two backend services and a React frontend. Backend Services: Ollama Backend: Powers the chatbot with the Llama 3.2 AI model for generating intelligent responses. Flask Backend: Acts as a middleware, managing API requests between the React frontend and the Ollama backend. React Frontend: Provides a user-friendly, responsive interface for interacting with the chatbot. Communicates with the Flask backend to process user inputs and display results seamlessly. The Docker containers ensure ease of deployment, scalability, and consistent operation across different environments. Users can access the chatbot through a browser at http://localhost:3000.' title='Chatbot' filename='chatbot.sh' link='chatbotinstructions'/>
                <ul>
                    <li>Visit our homepage.</li>
                    <li>Navigate to the <a style={linkstyle} href='/downloads'>Software Area</a> or <strong>Download Center</strong>.</li>
                    <li>Download the script file named <code>chatbot.sh</code>.</li>
                </ul>

                <h2 style={stepstyle}>Step 3: Execute the Setup Script</h2>
                <p>Run the setup script to install and configure the chatbot:</p>
                <ul>
                    <li>Open a terminal in the directory where you downloaded <code>chatbot.sh</code>.</li>
                    <li>Run the following command to execute the script:
                        <pre>bash chatbot.sh</pre>
                    </li>
                    <li>The script will perform the following actions:
                        <ul>
                            <li>Pull required files from the GitHub repository <code>github.com/mokmail/mollama</code>.</li>
                            <li>Download the AI model (Llama 3.2).</li>
                            <li>Create Docker images and containers to run the chatbot.</li>
                        </ul>
                    </li>
                </ul>
                <div style={noteStyle}>
                    <strong>Note:</strong> Ensure a stable internet connection during this step as the script downloads necessary files and images.
                </div>

                <h2 style={stepstyle}>Step 4: Access the Chatbot</h2>
                <p>Once the setup is complete, access the chatbot using your browser:</p>
                <ul>
                    <li>Open your web browser (e.g., Chrome, Firefox, Edge).</li>
                    <li>Enter the following URL in the address bar:
                        <pre style={codeBlockStyle}>http://localhost:3000</pre>
                    </li>
                    <li>Press <strong>Enter</strong> to load the chatbot interface.</li>
                </ul>

                <h2 style={stepstyle}>Step 5: Troubleshooting and Support</h2>
                <p>If you encounter any issues during installation or usage:</p>
                <ul>
                    <li>Check the terminal logs for error messages. Most errors provide clues about what went wrong.</li>
                    <li>If needed, stop and restart the setup by running:
                        <pre style={codeBlockStyle}>docker-compose down</pre>
                        and then re-executing the <code>chatbot.sh</code> script.
                    </li>
                    <li>For further assistance, visit our homepage and navigate to the <strong>Support</strong> section to contact us.</li>
                </ul>

                <p>Thank you for choosing our chatbot solution. We hope you enjoy this innovative and engaging experience!</p>
            </div>
        </div>
    );
};

export default ChatbotSetupInstructions;
