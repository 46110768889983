import React, { useEffect, useState } from 'react';


const useHass = ({entity_id}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiUrl = 'https://kmail.at:8123/api/states'; // Replace with your Home Assistant URL
  const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI2YTBkMzgyMWI0ZTM0MzhhYmEzYmQ1MDI3ZjRlMTQ0MSIsImlhdCI6MTczNTQ4NDA0OSwiZXhwIjoyMDUwODQ0MDQ5fQ.TxwXVX4BHnB_ZVrVkWrdqXl6jKNAOUCoFbDR7Mg1ky4'; // Replace with your Home Assistant token

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {

            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            
            
          },
          
        });

        if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
          setError('HTTP error! status: ${response.status}');
        }

        const result = await response.json();
        //const found = await Object.values(result).find(item => item['entity_id'] === entity_id);
        const found = await result.find(item => item['entity_id'] === entity_id);
        setData(found);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };




    fetchData();
  }, [apiUrl, authToken]);

//   if (loading) {
//     return <div className='text-sm text-green-500'>Loading...</div>;
//   }

//   if (error) {
//     return <div className='text-sm text-red-500'>Error: {error}</div>;
//   }

//   return (
//     <div className="">
     
//       {data && data.map((item) => (
//         <div className='text-sm  w-24 bg-slate-100 ' key={item.id}>

//         { item.entity_id === 'weather.forecast_home' ? <Weather item={item} /> : null }

            
           
//             </div>
//         ))}
//         <div>

//         </div>
//     </div>
//   );

return ([loading, error, data])
};

export default useHass;
