

import React, { useState , useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Button  , Input, TextField } from '@mui/material';


const HuggingFaceImageGenerator = () => {
  const huggingFaceApiKey = process.env.REACT_APP_API_KEY;

  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
    const [reloadFlag, setReloadFlag] = useState(false);

  const handleGenerateImage = async () => {
    if (!prompt) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-3.5-large', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${huggingFaceApiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inputs: prompt , width: 512, height: 512 }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImageUrl(imageUrl);
    } catch (err) {
      setError('Failed to generate image. Please try again.');
    } finally {
      setLoading(false);
    }
  };
const handleSaveImage = () => {

    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'generated_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
   useEffect(() => {
    if (reloadFlag) {
      setReloadFlag(false); // Reset the flag
    }
  }, [reloadFlag]);
 return (
    <div className=' flex flex-col items-center  m-auto  w-full '>
     
      <TextField className=' p-8 m-auto w-9/12 bg-slate-50' 
        type="text"
        variant="filled"
        placeholder="Enter your prompt"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        
      />
      {loading ? <CircularProgress /> :
      <div className='flex items-center m-auto justify-center  py-8 w-9/12'  >
         <Button className='w-full' sx={{background:"white"}}  variant = 'outlined' 
        onClick={handleGenerateImage}
        disabled={loading} >Generate Image</Button> 
         </div> 
     
        
        }
      
      {error && <p classname='text-red-300 '>{error}</p>}
      {imageUrl && (
        <div >
          
         
          <img src={imageUrl} alt="Generated"  className='  m-auto' />
          <br />

          <Button  className='flex flex-col items-center m-auto'  
           
          
            variant="outlined"
            color="primary"
            onClick={handleSaveImage}
            style={{ marginTop: '10px' }}
            >
              Save Image
              </Button>   
           

        </div>
      )}
    </div>
  );
};

export default HuggingFaceImageGenerator;