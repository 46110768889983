import React, { useState } from "react";
import Login from "./login";
const LoginModal = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="">
      <button onClick={openModal}>Login to download</button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
           <Login/>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};
export default LoginModal;