import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DevelopmentTimeChart = () => {
    // Data for the chart
    const data = {
        labels: [
            "Development",
            "Testing",
            "Debugging",
            "Deployment",
            "Maintenance",
            "Learning Curve",
        ],
        datasets: [
            {
                label: "Single Environment (hours)",
                data: [100, 50, 20, 10, 10, 5],
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
            {
                label: "Multi-Environment (hours)",
                data: [160, 100, 35, 17.5, 17.5, 15],
                backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Time Comparison: Single vs Multi-Environment Development",
            },
            legend: {
                position: "top",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Time (hours)",
                },
            },
            x: {
                title: {
                    display: true,
                    text: "Development Stages",
                },
            },
        },
    };

    return (
        <div className="h-1/2 w-1/2">
            <h2>Development Time Comparison</h2>
            <Bar data={data} options={options} />
        </div>
    );
};

export default DevelopmentTimeChart;
