import { Button , Divider } from "@mui/material";
const Widget = ({ title='Title', description =''  , logo=<div>logo</div> }) => {
const menu_syle ="flex flex-col h-full  w-1/2  border-x   items-center justify-center  text-slate-950 py-2 border-slate-400  "

return  (
    
    <div className={menu_syle}>
       <div className="h-12 w-12 ">
        {logo}
        </div> 
        
        <div className="p-2 text-2xl">{title}</div>
        
        <p className="p-2">{description}</p>
    </div>
)

}


export default Widget;